import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import ContentBlock from 'src/components/contentBlock';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "slider"
    }}>{`Slider`}</h1>
    <p>{`Provide an initial value of type `}<inlineCode parentName="p">{`Double`}</inlineCode>{`. Specify the min/max range with `}<inlineCode parentName="p">{`in:`}</inlineCode>{`
and adjust `}<inlineCode parentName="p">{`step:`}</inlineCode>{` to control granularity.`}</p>
    <ContentBlock imgName="Form-slider.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
    struct Slider<Label, ValueLabel> where Label : View, ValueLabel : View
*/
struct FormSlider: View {
    @State private var sliderValue: Double = 5
    
    var body: some View {
        Form {
            Section {
                Slider(value: $sliderValue, in: 0...10, step: 1)
                Text("Slider value: \(sliderValue)")
            }
            
            Section {
                Slider(value: $sliderValue, in: 0...10, step: 1)
                Text("Slider value: \(sliderValue, specifier: "%.0f")")
            }
        }
    }
}
    `}
  </code>
    </ContentBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      